<template>
  <v-window v-model="onboarding" reverse class="px-4">
    <v-window-item :value="1" class="py-4 px-0">
      <v-form style="width: 100%" class="text-center">
        <FeelingRatingPicker
          :selected="rating"
          @update="setRating"
          rating-class="d-flex justify-center"
        />

        <span v-if="sendFeedbackWithoutRating" class="d-block error--text my-2">
          {{ $ml.get("rating_error") }}
        </span>

        <v-scroll-y-transition>
          <v-chip-group
            v-if="evaluated && rating < 5"
            v-model="sentencesSelected"
            column
            multiple
            active-class="feedback-sentence"
            class="mt-3"
          >
            <v-chip v-for="(textKey, i) in sentences" :key="i" :value="textKey">
              {{ $ml.get(textKey) }}
            </v-chip>
          </v-chip-group>
        </v-scroll-y-transition>

        <v-textarea
          filled
          class="mt-5 mx-auto"
          :label="$ml.get('user_comments')"
          value="message"
          v-model="message"
          :placeholder="
            sentencesSelected && sentencesSelected.length > 0
              ? $ml.get('message_placeholder')
              : ''
          "
          :persistent-placeholder="
            sentencesSelected && sentencesSelected.length > 0
          "
        />

        <v-btn
          color="primary"
          large
          class="text-none text-body-1 font-weight-medium px-8 mx-auto"
          :loading="loading"
          @click="registerUserFeedback"
        >
          {{ $ml.get("send_feedback") }}
        </v-btn>
      </v-form>
    </v-window-item>

    <v-window-item :value="2" class="mb-4">
      <v-card
        min-height="250"
        elevation="0"
        class="mx-auto my-4 d-flex flex-column align-center"
      >
        <v-img :src="image" :width="imgWidth"></v-img>

        <v-card-title> {{ $ml.get("successful_feedback_sent") }} </v-card-title>

        <v-btn
          @click="resetValues"
          v-if="!showReviewMarketplace"
          color="primary"
          large
          text
          class="text-none text-body-1 font-weight-medium"
        >
          <v-icon v-text="'mdi-pencil'" left />
          {{ $ml.get("edit_feedback") }}
        </v-btn>

        <v-btn
          v-else
          @click="redirectToMarketplace"
          color="primary"
          large
          text
          class="text-none text-body-1 font-weight-medium"
        >
          <v-icon v-text="'mdi-store'" left />
          {{ $ml.get("rate_app_on_marketplace") }}
        </v-btn>

        <v-btn
          v-if="showReviewMarketplace"
          @click="resetValues"
          class="text-none text-body-2 font-weight-medium mt-2"
          text
          color="secondary lighten-2"
        >
          <v-icon v-text="'mdi-pencil'" left />
          {{ $ml.get("new_feedback") }}
        </v-btn>

        <v-btn
          v-else-if="showDismiss"
          @click="close"
          class="text-none text-body-2 font-weight-medium mt-2"
          text
          color="secondary lighten-2"
        >
          {{ $ml.get("dismiss") }}
        </v-btn>
      </v-card>
    </v-window-item>
  </v-window>
</template>
<script>
import FeelingRatingPicker from "@/components/forms/field_pickers/FeelingRatingPicker";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { openInNewTab } from "@/helpers/utils";

export default {
  name: "FeedbackRating",

  props: {
    action: { type: String, required: true },
    hideCommentByDefault: { type: Boolean, default: false },
    imgWidth: { type: String, default: "170" },
    ratingClass: { type: String, default: "" },
    selected: { type: [Number, String], default: 0 },
    showDismiss: { type: Boolean, default: false },
    width: { type: [Number, String], default: "" },
  },

  components: { FeelingRatingPicker },

  data: () => ({
    loading: false,
    rating: 0,
    sendFeedbackWithoutRating: false,
    message: "",
    onboarding: 1,
    image: require("@/assets/images/feedback_check.png"),
    sentencesSelected: [],
  }),

  computed: {
    ...mapGetters(["token", "currentUserFeedbacks"]),

    disableFeedback() {
      return this.rating == 0;
    },

    iconType() {
      return this.icons[this.color] || false;
    },

    evaluated() {
      return this.rating > 0;
    },

    showCommentSession() {
      if (this.hideCommentByDefault) {
        return this.evaluated;
      }
      return true;
    },

    showReviewMarketplace() {
      return this.rating >= 4;
    },

    sentencesByAction() {
      return {
        group_add_or_remove_users: [
          "common_did_not_work",
          "common_confusing_interface",
          "common_slow_performance",
          "group_add_or_remove_users_incorrect",
          "group_add_or_remove_users_missing",
        ],
        change_user_password: [
          "common_did_not_work",
          "common_confusing_interface",
          "common_slow_performance",
          "change_user_password_incorrect",
          "change_user_password_missing",
          "change_user_password_usability",
        ],
        customize_login: [
          "common_did_not_work",
          "common_confusing_interface",
          "common_options_missing",
          "common_slow_performance",
          "customize_login_preview",
        ],
        control_activation: [
          "common_did_not_work",
          "common_confusing_interface",
          "common_options_missing",
          "common_slow_performance",
          "control_activation_usability",
          "control_activation_frequent",
        ],
      };
    },

    sentences() {
      return this.sentencesByAction[this.action] || [];
    },
  },

  watch: {
    rating() {
      this.sentencesSelected = [];
      this.$emit("update", this.rating);
    },

    selected() {
      if (this.selected) {
        this.rating = this.selected;
      }
    },
  },

  methods: {
    ...mapActions(["setSnackbarCustomMessage"]),
    ...mapMutations([
      "setCurrentUser",
      "setSnackbarDefault",
      "updateCurrentUserFeedbacks",
    ]),

    close() {
      this.resetValues();
      this.$emit("close");
    },

    setRating(event) {
      this.rating = event;
      this.sendFeedbackWithoutRating = false;
    },

    resetValues() {
      this.message = "";
      this.rating = 0;
      this.onboarding = 1;
    },

    registerUserFeedback() {
      if (!this.rating) {
        this.sendFeedbackWithoutRating = true;
        return;
      }

      const url = "/users/feedback";
      this.loading = true;

      const payload = {
        action: this.action,
        feedback_rating: this.rating,
        feedback_note: this.message || "user_no_comment",
        feedback_sentences: this.sentencesSelected,
      };

      this.$axios
        .post(url, payload, {
          headers: { Authorization: "Bearer " + this.token },
        })
        .then(() => {
          this.onboarding = 2;
          const date = new Date();
          this.updateCurrentUserFeedbacks({
            ...this.currentUserFeedbacks,
            [this.action]: date.toISOString().split("T")[0],
          });
          this.$emit("evaluated", true);
        })
        .catch((error) => {
          this.setSnackbarDefault({
            message: "feedback_error",
            color: "error",
            show: true,
          });
          this.$emit("evaluated", false);
          console.error("registerUserFeedback", error);
          this.$emit("update", 0);
          this.close();
        })
        .finally(() => (this.loading = false));
    },

    redirectToMarketplace() {
      const link = `https://workspace.google.com/marketplace/app/conecta_control/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
      openInNewTab(link);
    },
  },

  beforeMount() {
    this.rating = this.selected;
  },
};
</script>

<style>
.v-chip-group .feedback-sentence {
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
  color: var(--v-background-base) !important;
}
</style>
